import "core-js/modules/es.array.reduce.js";
import store from "@/state/store.js";
import _ from "lodash";
export default {
  data() {
    return {
      response: [],
      pageSize: 0,
      total: 0,
      currentPage: 1,
      page: 1,
      search: "",
      dateRange: [],
      tableLoading: false,
      rangeLoading: false,
      users_details: {
        users_called: 0,
        users_not_called: 0
      },
      top_up_amount: 0
    };
  },
  computed: {
    users() {
      return store.getters["admin/users/newUsers"].filter(user => {
        return user.name.toLowerCase().includes(this.search.toLowerCase()) || user.phone.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    totalUsers() {
      return this.users_details.users_called + this.users_details.users_not_called;
    },
    totalTopUp() {
      if (this.response && this.response.length === 0) {
        return 0;
      }
      return this.response.data.reduce((sum, user) => {
        return sum + user.top_up_amount;
      }, 0).toLocaleString("en-US", {
        style: "currency",
        currency: "GHC"
      });
      ;
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      if (_.isEmpty(this.dateRange)) {
        this.tableLoading = true;
        store.dispatch("admin/users/getNewUsers", {
          page: val
        }).then(data => {
          this.tableLoading = false;
        }).finally(() => {
          this.tableLoading = false;
        });
        return;
      }
      this.tableLoading = true;
      let formattedFrom = new Date(this.dateRange[0]).toISOString();
      let formattedTo = new Date(this.dateRange[1]).toISOString();
      store.dispatch("admin/users/getNewUsers", {
        page: val,
        from: formattedFrom,
        to: formattedTo
      }).then(data => {
        this.tableLoading = false;
      }).finally(() => {
        this.tableLoading = false;
      });
    },
    searchUsers() {
      if (_.isEmpty(this.dateRange)) {
        this.tableLoading = true;
        store.dispatch("admin/users/getNewUsers", {
          page: 1
        }).then(data => {
          this.tableLoading = false;
        }).finally(() => {
          this.tableLoading = false;
        });
        return;
      }
      this.tableLoading = true;
      let formattedFrom = new Date(this.dateRange[0]).toISOString();
      let formattedTo = new Date(this.dateRange[1]).toISOString();
      store.dispatch("admin/users/getNewUsers", {
        page: 1,
        from: formattedFrom,
        to: formattedTo
      }).then(data => {
        this.pageSize = data.per_page;
        this.total = data.total;
        this.tableLoading = false;
        this.users_details.users_called = data.users_called;
        this.users_details.users_not_called = data.users_yet_to_call;
        this.response = data;
      }).finally(() => {
        this.tableLoading = false;
      });

      // set from and to for exporting
      store.dispatch("admin/users/setNewUsersToAndFrom", {
        from: formattedFrom,
        to: formattedTo
      });
    },
    toggleFollowUp(id, is_follow_up) {
      this.tableLoading = true;
      let payload = {
        user_id: id,
        status: is_follow_up == 0 ? 1 : 0
      };
      store.dispatch("admin/users/toggleFollowUp", payload).then(response => {
        this.$message({
          type: "success",
          message: "User called status updated successfully"
        });
        this.tableLoading = true;
        let formattedFrom = new Date(this.dateRange[0]).toISOString();
        let formattedTo = new Date(this.dateRange[1]).toISOString();
        store.dispatch("admin/users/getNewUsers", {
          page: this.page,
          from: formattedFrom,
          to: formattedTo
        }).then(data => {
          this.pageSize = data.per_page;
          this.total = data.total;
          this.tableLoading = false;
          this.users_details.users_called = data.users_called;
          this.users_details.users_not_called = data.users_yet_to_call;
        }).finally(() => {
          this.tableLoading = false;
        });
      }).catch(err => {
        this.$message({
          type: "error",
          message: "There was an error updating user status"
        });
      }).finally(() => {
        // this.tableLoading = false
      });
    }
  },
  mounted() {
    this.tableLoading = true;
    store.dispatch("admin/users/getNewUsers", {
      page: 1
    }).then(response => {
      this.total = response.total;
      this.tableLoading = false;
      this.pageSize = response.per_page;
      this.users_details.users_called = response.users_called;
      this.users_details.users_not_called = response.users_yet_to_call;
      this.response = response.data;
    }).catch(err => {
      this.$message({
        type: "error",
        message: err.response.data.message
      });
    }).finally(() => {
      this.tableLoading = false;
    });
  }
};