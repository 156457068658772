var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "search-bar"
  }, [_c('div', {
    staticClass: "users-called"
  }, [_c('small', [_vm._v("Total Users: "), _c('span', {
    staticStyle: {
      "color": "#28a745"
    }
  }, [_vm._v(_vm._s(_vm.totalUsers))])]), _c('small', [_vm._v("Total Top Up: "), _c('span', {
    staticStyle: {
      "color": "#ee6463"
    }
  }, [_vm._v(_vm._s(_vm.totalTopUp))])])]), _c('div', {
    staticClass: "choose-date"
  }, [_c('el-date-picker', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.rangeLoading,
      expression: "rangeLoading"
    }],
    attrs: {
      "type": "daterange",
      "start-placeholder": "From",
      "end-placeholder": "To",
      "name": "pick-date"
    },
    on: {
      "change": _vm.searchUsers
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)]), _c('div', {
    staticClass: "table-container"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      "data": _vm.users,
      "height": "50vh",
      "header-cell-style": {
        background: 'rgba(212, 216, 226, 0.2)'
      }
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Name",
      "prop": "name"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Phone",
      "prop": "phone"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Email",
      "prop": "email"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Referred From",
      "prop": "referral"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Topped Up"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.top_up_status == 1 ? _c('span', {
          staticStyle: {
            "color": "#28a745"
          }
        }, [_vm._v("Topped Up Before")]) : _c('span', {
          staticStyle: {
            "color": "#f7921c"
          }
        }, [_vm._v("Yet to top up")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Created At"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', [_vm._v(" " + _vm._s(_vm.moment(scope.row.created_at).format("ll hh:mm:ss A")) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.is_follow_up == 0 ? _c('span', {
          staticStyle: {
            "color": "#f7921c"
          }
        }, [_c('strong', [_vm._v("Not Called")])]) : _c('span', {
          staticStyle: {
            "color": "#28a745"
          }
        }, [_c('strong', [_vm._v("Called")])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          class: [scope.row.is_follow_up == 0 ? 'is-called' : 'is-not-called'],
          attrs: {
            "type": "primary",
            "size": "mini"
          },
          on: {
            "click": function ($event) {
              return _vm.toggleFollowUp(scope.row.id, scope.row.is_follow_up);
            }
          }
        }, [scope.row.is_follow_up == 0 ? _c('span', [_c('strong', [_vm._v("Mark As Called")])]) : _c('span', [_c('strong', [_vm._v("Mark As Not Called")])])])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-md-12",
    staticStyle: {
      "text-align": "center",
      "margin-top": "2em"
    }
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "page-size": _vm.pageSize,
      "total": _vm.total
    },
    on: {
      "current-change": _vm.handleCurrentChange
    }
  })], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };